<script setup lang="ts">
const router = useRouter()

const muted = 'bg-muted-50 dark:bg-muted-900'
const white = 'bg-white dark:bg-muted-900'
const backgroundColor = ref(muted)

const setBackgroundColor = (route: any) => {
  setTimeout(() => {
    backgroundColor.value = route.meta.whiteBackground ? white : muted
  }, 500)
}

router.afterEach(setBackgroundColor)

setBackgroundColor(router.currentRoute.value)
</script>

<template>
  
  <div
    :class="backgroundColor"
    class="absolute flex min-h-screen w-full flex-col overflow-x-hidden"
  >
    <PublicHeader />
    <PublicCmsSidebarMenu />

    
    <Container class="grow px-4 pb-4 pt-10">
      <slot />
    </Container>

    <PublicFooter />
    <TairoPanels />
  </div>
</template>
